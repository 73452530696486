/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'danger': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.966 1.023a1 1 0 00-1.758 0L.122 15.931a1 1 0 00.88 1.477h16.17a1 1 0 00.88-1.477L9.965 1.023zm-.353 4.648a.642.642 0 10-1.285 0v3.864c0 .236.003.467.008.693a32.997 32.997 0 00.091 1.387l.033.307a.513.513 0 001.018 0c.01-.106.02-.208.028-.307a15.885 15.885 0 00.091-1.386c.01-.227.015-.458.015-.694V5.671zM8.07 13.94c-.046.12-.068.249-.068.386 0 .141.022.273.068.393.05.116.116.218.197.308.087.089.188.157.304.204.117.053.241.08.373.08a.896.896 0 00.372-.08.874.874 0 00.304-.204 1.008 1.008 0 00.274-.701.988.988 0 00-.076-.386.912.912 0 00-.198-.316.975.975 0 00-.304-.212.896.896 0 00-.372-.079.896.896 0 00-.373.079.975.975 0 00-.304.212c-.08.09-.146.195-.197.316z" _fill="#EB5757"/>'
  }
})
